import './App.css';
import React, { useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table'
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

class TrafficChangeBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      metadata: {
        dateRange: {
          startTime: "",
          endTime: ""
        },
        confidenceInfo: {
          level: "",
          annotations: []
        },
        aggInterval: "",
        lastUpdated: ""
      },
      data: {
        total: [],
        http: []
      }
    }
    this.traffic_chart = {
      labels: [],
      datasets: [{
        label: "Total",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        data: []
      }, {
        label: "Http",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        data: []
      }]
    }
  }

  componentDidMount() {
    fetch("https://workers.chengzhiyi2000.workers.dev/traffic-change")
      .then(response => response.text())
      .then(json => {
        var obj = JSON.parse(json)
        this.setState({
          metadata: {
            dateRange: {
              startTime: obj["meta"]["dateRange"]["startTime"],
              endTime: obj["meta"]["dateRange"]["endTime"]
            },
            confidenceInfo: {
              level: obj["meta"]["confidenceInfo"]["level"],
              annotaions: obj["meta"]["confidenceInfo"]["annotations"].map(
                ob => {
                  return {
                    dataSource: ob["dataSource"],
                    startDate: ob["startDate"],
                    endDate: ob["endDate"],
                    eventType: ob["eventType"],
                    description: ob["description"]
                  }
                }
              ),
              aggInterval: obj["meta"]["aggInterval"],
              lastUpdated: obj["meta"]["lastUpdated"]
            }
          },
          data: {
            total: obj["data"]["total"].map(ob => {
              return {
                values: +ob["value"],
                timestamps: ob["timestamp"]
              }
            }),
            http: obj["data"]["http"].map(ob => {
              return {
                values: +ob["value"],
                timestamps: ob["timestamp"]
              }
            })
          }
        })
      })
      .then(() => {
        this.traffic_chart.labels = this.state.data.http.map(ob => { return ob.timestamps })
        this.traffic_chart.datasets[0].data = this.state.data.total.map(ob => { return ob.values })
        this.traffic_chart.datasets[1].data = this.state.data.http.map(ob => { return ob.values })
      })
  }

  render() {
    if (this.traffic_chart.datasets[0].data.length !== 0 && this.traffic_chart.datasets[1].data.length !== 0) {
      return (
        <div id="traffic-change">
          <Bar
            data={this.traffic_chart}
            options={{
              responsive: true,
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: false
                }
              },
              plugins: {
                title: {
                  display: true,
                  text: "the current traffic change based on the 30 day internet trends data point",
                  fontSize: 20
                },
                legend: {
                  display: true,
                  position: "right"
                }
              }
            }}
          />
        </div>
      )
    } else {
      return (<div>Loading...</div>)
    }
  }
}

function PopularDomainTable() {
  const [rankEntries, setData] = useState([])

  useEffect(() => {
    fetch("https://workers.chengzhiyi2000.workers.dev/popular-domains")
      .then(response => response.text())
      .then(json => {
        var obj = JSON.parse(json)
        setData(obj["rankEntries"].map(ob => {
          return {
            rank: ob["rank"],
            rankChange: ob["rankChange"],
            domain: ob["domain"],
            category: ob["category"]
          }
        }).sort((v1, v2) => (v1.rank - v2.rank)))
      })
  })

  const columns = useMemo(() => [
    { Header: "Rank", accessor: "rank" },
    { Header: "RankChange", accessor: "rankChange" },
    { Header: "Domain", accessor: "domain" },
    { Header: "Category", accessor: "category" }
  ], [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: rankEntries })
  if (rankEntries.length !== 0) {
    return (
      <div className="container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  } else {
    return (<div>Loading...</div>)
  }

}

class DDOSAttackPie extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      metadata: {
        dateRange: {
          startTime: "",
          endTime: ""
        },
        confidenceInfo: {
          level: "",
          annotations: []
        },
        aggInterval: "",
        lastUpdated: ""
      },
      data: []
    }

    this.ddos_attack = {
      labels: [],
      datasets: [{
        label: "Value",
        data: [],
        backgroundColor: [],
        borderColor: []
      }/*,{
        label: "Percentage",
        data: [],
        backgroundColor: [],
        borderColor: []
      }*/]
    }

  }

  componentDidMount() {
    fetch("https://workers.chengzhiyi2000.workers.dev/attack-layer3")
      .then(response => response.text())
      .then(json => {
        var obj = JSON.parse(json)
        this.setState({
          metadata: {
            dateRange: {
              startTime: obj["meta"]["dateRange"]["startTime"],
              endTime: obj["meta"]["dateRange"]["endTime"]
            },
            confidenceInfo: {
              level: obj["meta"]["confidenceInfo"]["level"],
              annotations: obj["meta"]["confidenceInfo"]["annotations"].map(
                ob => {
                  return {
                    dataSource: ob["dataSource"],
                    startDate: ob["startDate"],
                    endDate: ob["endDate"],
                    eventType: ob["eventType"],
                    description: ob["description"]
                  }
                }
              ),
              aggInterval: obj["meta"]["aggInterval"],
              lastUpdated: obj["meta"]["lastUpdated"]
            }
          },
          data: obj["data"].map(ob => {
            return {
              values: +ob["value"],
              timestamps: ob["timestamp"]
            }
          })
        })
      })
      .then(() => {
        this.ddos_attack.labels = this.state.data.map(ob => { return ob.timestamps })
        let sum = this.state.data.reduce((a, v) => a = a + v.values, 0)
        this.ddos_attack.datasets[0].data = this.state.data.map(ob => { return ob.values })
        // this.ddos_attack.datasets[1].data = this.state.data.map(ob => { return ob.values / sum })
        for (let i = 0; i < this.ddos_attack.datasets[0].data.length; i++) {
          var v1 = Math.floor(Math.random() * 256), v2 = Math.floor(Math.random() * 256), v3 = Math.floor(Math.random() * 256)
          this.ddos_attack.datasets[0].backgroundColor.push(`rgba(${v1},${v2},${v3},1)`)
          this.ddos_attack.datasets[0].borderColor.push(`rgba(${v1},${v2},${v3},1)`)
        }
      })
  }

  render() {
    if (this.ddos_attack.datasets[0].data.length !== 0) {
      return (
        <div>
          <Pie
            data={this.ddos_attack}
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: "Layer 3 DDoS Attack over the last 30 Days",
                  fontSize: 20
                },
              }
            }}
          />
        </div>
      )
    } else {
      return (<div>Loading...</div>)
    }
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.traffic_chart_http_on = true;
    this.traffic_chart_total_on = true;

    this.handleClickPopularDescend = this.handleClickPopularDescend.bind(this)
    this.handleClickTrafficHttp = this.handleClickTrafficHttp.bind(this)
    this.handleClickTrafficTotal = this.handleClickTrafficTotal.bind(this)
  }

  handleClickTrafficHttp(event) {
    this.traffic_chart_http_on = !this.traffic_chart_http_on
    if (this.traffic_chart_http_on) {
      this.traffic_chart.datasets[1].data = this.state.traffic_change.data.http.map(ob => { return ob.values })
    } else {
      this.traffic_chart.datasets[1].data = []
    }
  }

  handleClickTrafficTotal(event) {
    this.traffic_chart_total_on = !this.traffic_chart_total_on
    if (this.traffic_chart_total_on) {
      this.traffic_chart.datasets[0].data = this.state.traffic_change.data.total.map(ob => { return ob.values })
    } else {
      this.traffic_chart.datasets[0].data = []
    }
  }

  handleClickPopularDescend(event) {
    // reverse
    this.state.popular_domain.rankEntries.sort((v1, v2) => (v2.rank - v1.rank))
  }

  render() {
    return (
      <div>
        <p>&nbsp;</p>
        <TrafficChangeBar />
        <p>&nbsp;</p>
        <PopularDomainTable />
        <p>&nbsp;</p>
        <DDOSAttackPie />
      </div>
    )
  }
}


export default App;
